<template>
  <div style="width: 100%;">

    <div style="width: 100%; height: 100%; overflow: hidden" class="bgImg">
      <div style="width: 55%; background: #FFFFFF;margin: 6% auto 50px;">

        <div style="width: 100%; margin: 0 auto; padding-top: 30px">
          <div class="item" style="font-size: 22px; color: #0080FF">注册账号</div>


          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                   class="demo-ruleForm" style="margin-top: 30px" @submit.native.prevent>


            <el-form-item prop="name">
              <el-row :gutter="20">
                <el-col :span="13">
                  <el-input
                      placeholder="请输入手机号"
                      style="float: left;"
                      v-model="ruleForm.name"
                      prefix-icon="el-icon-user-solid"
                  />
                </el-col>
                <el-col :span="11">
                  <span class="text">*</span>
                  <span class="text2">11位手机号，避免收不到开通密保信息</span>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item prop="password">
              <el-row :gutter="20">
                <el-col :span="13">
                  <el-input
                      placeholder="请输入密码"
                      style="float: left;margin-top: 12px;"
                      type="password"
                      v-model="ruleForm.password"
                      prefix-icon="el-icon-lock"
                  />
                </el-col>
                <el-col :span="11">
                  <span class="text">*</span>
                  <span class="text2">账户的密码必须大于6位字符</span>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item prop="checkPassword">
              <el-row :gutter="20">
                <el-col :span="13">
                  <el-input
                      placeholder="请再次输入密码"
                      style="float: left;margin-top: 12px;"
                      type="password"
                      v-model="ruleForm.checkPassword"
                      prefix-icon="el-icon-lock"
                  />
                </el-col>
                <el-col :span="11">
                  <span class="text">*</span>
                  <span class="text2">请重复一次登录密码</span>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item prop="realName">
              <el-row :gutter="20">
                <el-col :span="13">
                  <el-input
                      placeholder="请输入真实姓名"
                      style="float: left;margin-top: 12px;"
                      v-model="ruleForm.realName"
                      prefix-icon="el-icon-folder"
                  />
                </el-col>
                <el-col :span="11">
                  <span class="text">*</span>
                  <span class="text2">请输入您的真实姓名，以便认证</span>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item prop="teachingDiscipline">
              <el-row :gutter="20">
                <el-col :span="13">
                  <el-input
                      placeholder="请输入执教学科"
                      style="float: left;margin-top: 12px;"
                      v-model="ruleForm.teachingDiscipline"
                      prefix-icon="el-icon-tickets"
                  />
                </el-col>
                <el-col :span="11">
                  <span class="text">*</span>
                  <span class="text2">请正确输入你执教的学科，以免权限受限</span>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item prop="teachingArea">
              <el-row :gutter="20">
                <el-col :span="13">
                  <el-input
                      placeholder="请输入执教地区"
                      style="float: left;margin-top: 12px;"
                      v-model="ruleForm.teachingArea"
                      prefix-icon="el-icon-location-information"
                  />
                </el-col>
                <el-col :span="11">
                  <span class="text">*</span>
                  <span class="text2">请正确输入，以便认证</span>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item prop="teachingUnit">
              <el-row :gutter="20">
                <el-col :span="13">
                  <el-select
                      style="float: left;margin-top: 12px;width: 100%"
                      v-model="ruleForm.teachingGrade"
                      placeholder="请选择执教年级"
                  >
                    <i slot="prefix" class="el-input__icon el-icon-s-operation"/>
                    <el-option label="高一" value="高一"></el-option>
                    <el-option label="高二" value="高二"></el-option>
                    <el-option label="高三" value="高三"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="11">
                  <span class="text">*</span>
                  <span class="text2">请选择执教的年级</span>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item prop="teachingUnit">
              <el-row :gutter="20">
                <el-col :span="13">
                  <el-input
                      placeholder="请输入执教单位"
                      style="float: left;margin-top: 12px;"
                      v-model="ruleForm.teachingUnit"
                      prefix-icon="el-icon-office-building"
                  />
                </el-col>
                <el-col :span="11">
                  <span class="text">*</span>
                  <span class="text2">正确格式： **市**县、区**中学</span>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item>
              <div class="item">
                <button
                    style="background: dodgerblue; border-radius: 5px; color: white; width: 50%; padding: 10px; cursor: pointer"
                    @click="submitForm('ruleForm')">全部填写完成,提交
                </button>
              </div>

              <div class="item" style="padding: 10px 0;">
                <span style="cursor: pointer; color: #409EFF" @click="$router.push('/login')">去登录</span>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
        <Bottom/>
    </div>


  </div>
</template>

<script>

import Bottom from "../home/bottom";
import {register} from "../api/user";

export default {
  components: {Bottom},
  name: "Register",
  data() {
    const validatorCheckPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        // 用户名 就是手机号
        name: '',
        // 用户密码
        password: '',
        // 确认密码
        checkPassword: '',
        // 真实姓名
        realName: '',
        // 执教学科
        teachingDiscipline: '',
        // 执教年级
        teachingGrade: '',
        // 执教地区
        teachingArea: '',
        // 执教单位
        teachingUnit: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {min: 11, max: 11, message: '长度在11个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, message: '账户的密码必须大于6位字符', trigger: 'blur'}
        ],
        checkPassword: [
          {validator: validatorCheckPassword, trigger: 'blur'}
        ],
        realName: [
          {required: true, message: '请输入真实姓名', trigger: 'blur'},
        ],
        teachingDiscipline: [
          {required: true, message: '请输入执教学科', trigger: 'blur'},
        ],
        teachingGrade: [
          {required: true, message: '请选择执教年级', trigger: 'blur'},
        ],
        teachingArea: [
          {required: true, message: '请输入执教地区', trigger: 'blur'},
        ],
        teachingUnit: [
          {required: true, message: '请输入执教单位', trigger: 'blur'},
        ],
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          register(this.ruleForm).then(res => {
            const d = res.data;
            const _this = this;
            if (d.code === 1) {
              _this.$message.error(d.msg)
            } else {
              this.$message({
                    type: "success",
                    message: d.msg
                  })
              setTimeout(() => {
                this.$router.push('/login')
              }, 1000)
            }
          })
        } else {
          return false;
        }
      });
    },

  }
}
</script>

<style scoped>
.bgImg {
  background: url("../assets/register_background.png") no-repeat;
  background-size: 100% 100vh;
}

.item {
  text-align: center;
  padding: 10px 0
}

.text {
  color: #F72323;
}

.text2 {
  margin-bottom: auto;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFang SC-Regular, PingFang SC, serif;
}

::v-deep .el-input__inner {
  height: 40px;
}


</style>
