<template>
  <div
      style="
        width: 100%;
        padding: 10px 0;
        bottom: 0;
      "
  >
    <div style="
      text-align: center;
      font-size: 15px;
">
      <div style=" padding-top: 10px;padding-bottom: 10px;">
        Copyrigt@2007-2018 备案: 广州高考网 版权所有 www.020gk.com
      </div>

      <div style=" padding-top: 10px;padding-bottom: 10px;">
        版权所有 @广州高考网 版权所有 未经许可不得私自复制镜像。
      </div>
      <div style=" padding-top: 10px;padding-bottom: 10px;">
        您的访问IP:{{ ip }}
        <span style="color: #0080FF;font-size: 12px" v-if="number">
          （很幸运，您是第{{number}}位访客。）
        </span>
      </div>
    </div>
  </div>

</template>

<script>
import {findCount, findIp} from "../api/user";
import cookie from "js-cookie";

export default {
  name: 'bottom',
  data() {
    return {
      ip: '',
      number: ''
    }
  },
  created() {
    this.getIp();
    const token = cookie.get('access_token');
    if (!token) {
      return;
    }
    this.findUserCount();
  },
  methods: {
    getIp() {
      findIp().then(res => {
        this.ip = res.data.data;
      })
    },
    findUserCount() {
      findCount().then(res => {
        this.number = res.data.data.visitorNumber;
      })
    }
  }
}
</script>
